<template>
  <div class="container">
    <div class="top">
      <div @click="toHome" class="goBack">返回系统</div>
      <div style="width:90vw">
        <el-tabs v-model="activeNames" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.id" v-for="(item, index) in data_list" :key="index"></el-tab-pane>
        </el-tabs>

      </div>
      <!-- <div @click="tz"  class="goBack">四色图</div> -->
    </div>
    <div class="home">
      <div class="left">
        <div class="risk">
          <div class="risk_top">
            <div class="risk_title">风险概况</div>
            <div class="risk_count">风险点总数量</div>
            <div class="risk_count" style="fontsize: 30px">{{ riskNum }}个</div>
          </div>
          <div class="risk_body" id="risk"></div>
        </div>
        <div class="report">
          <div class="risk_top">
            <div style="
                color: #fff;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
              ">
              <div>隐患总计</div>
              <div>总计 {{ yh_zong }}</div>
            </div>
            <div style="
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: space-around;
              ">
              <div style="width: 27%; height: 100%">
                <div style="
                    width: 55%;
                    margin: 0 auto;
                    color: #fff;
                    text-align: center;
                  ">
                  <div style="width: 100%; margin-top: 20px">
                    <img src="../assets/img/zhongda_img.png" alt="" style="width: 100%" />
                  </div>
                  <div>重大隐患</div>
                  <div>{{ zhongda_yinhuan }}</div>
                </div>
              </div>
              <div style="width: 27%; height: 100%">
                <div style="
                    width: 55%;
                    margin: 0 auto;
                    color: #fff;
                    text-align: center;
                  ">
                  <div style="width: 100%; margin-top: 20px">
                    <img src="../assets/img/yiban_img.png" alt="" style="width: 100%" />
                  </div>
                  <div>一般隐患</div>
                  <div>{{ yiban_yihuan }}</div>
                </div>
              </div>
              <div style="width: 27%; height: 100%">
                <div style="width: 100%; height: 100%" id="yh_zhili" @click="tiaozhuan4"></div>
              </div>
            </div>
            <!-- <div class="risk_title">隐患概况</div>
            <div class="risk_count">隐患总数量</div>
            <div class="risk_count" style="fontsize: 30px">
              {{ reportNum }}个
            </div> -->
            <div style="
                width: 100%;
                height: 80px;
                position: relative;
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
              ">
              <div style="
                  color: #fff;
                  width: 20%;
                  text-align: center;
                  z-index: 999;
                ">
                <div style="
                    height: 30px;
                    width: 60%;
                    background-color: #f79c10;
                    line-height: 30px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                  ">
                  待整改
                </div>
                <div style="width: 100%; height: 30px">
                  <div style="
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: #f79c10;
                      margin: 0 auto;
                    "></div>
                </div>
                <div>{{ dai_zg }}</div>
              </div>
              <div style="
                  color: #fff;
                  width: 20%;
                  text-align: center;
                  z-index: 999;
                ">
                <div style="
                    height: 30px;
                    width: 60%;
                    background-color: #2d8df7;
                    line-height: 30px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                  ">
                  待验收
                </div>
                <div style="width: 100%; height: 30px">
                  <div style="
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: #2d8df7;
                      margin: 0 auto;
                    "></div>
                </div>
                <div>{{ dai_ys }}</div>
              </div>
              <div style="
                  color: #fff;
                  width: 20%;
                  text-align: center;
                  z-index: 999;
                ">
                <div style="
                    height: 30px;
                    width: 60%;
                    background-color: #39bc70;
                    line-height: 30px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                  ">
                  已完成
                </div>
                <div style="width: 100%; height: 30px">
                  <div style="
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: #39bc70;
                      margin: 0 auto;
                    "></div>
                </div>
                <div>{{ yi_wc }}</div>
              </div>
              <div style="
                  width: 100%;
                  height: 10px;
                  background-color: #f9fefc;
                  border-radius: 10px;
                  position: absolute;
                  top: 45px;
                  z-index: 99;
                "></div>
            </div>
          </div>

          <!-- <div class="risk_body" id="report"></div> -->
        </div>
        <!-- <div class="report" id="report"></div> -->
        <div class="training">
          <div style="color: #fff; display: flex; justify-content: space-between">
            <div>隐患排查总计</div>
            <div>总计 {{ pc_zongji }}</div>
          </div>
          <div style="
              width: 100%;
              height: 110px;
              display: flex;
              justify-content: space-around;
            ">
            <div style="width: 30%; height: 100%" @click="tiaozhuan1">
              <div style="width: 100%; height: 80%" id="yuan"></div>
              <div style="color: #fff; width: 100%; text-align: center">
                排查类型分布
              </div>
            </div>

            <div style="width: 30%; height: 100%" id="yinhuan_cxl" @click="tiaozhuan2"></div>
          </div>
          <div id="zhutu" style="width:100%;height: 180px;" @click="tiaozhuan3"></div>
        </div>
      </div>
      <div class="center">
        <!-- <div class="map" id="map"></div> -->
        <!-- <div class="bar" id="bar"></div> -->

        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <div id="box" v-drag></div>
        <div id="box1" v-drag></div>
        <div id="box2" v-drag></div>
        <div id="box3" v-drag></div>
        <!-- <img class="img" :src=this.image  @click="dialogVisible = true"> -->
        <table border="1" class="tabs">
          <tr>
            <td>颜色</td>
            <td>风险等级</td>
          </tr>
          <tr>
            <td class="red"></td>
            <td>重大</td>
          </tr>
          <tr>
            <td class="orange"></td>
            <td>较大</td>
          </tr>
          <tr>
            <td class="yellow"></td>
            <td>一般</td>
          </tr>
          <tr>
            <td class="blue"></td>
            <td>低风险</td>
          </tr>
        </table>
        <img class="img" :src="this.image" />
        <el-dialog title="四色分布图管理" :visible.sync="dialogVisible" fullscreen="true" :before-close="handleClose">
          <div id="box" v-drag>低隐患</div>
          <div id="box1" v-drag>一般隐患</div>
          <div id="box2" v-drag>较大隐患</div>
          <div id="box3" v-drag>重大隐患</div>
          <img class="imgs" :src="this.image" @click="dialogVisible = true" />
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" class="qx">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false" class="qd">确 定</el-button>
          </span>
        </el-dialog>

        <!-- <div class="bar" id="assessment"></div> -->
      </div>
      <div class="right">
        <!-- <div class="controls" id="controls"></div> -->

        <!-- <div class="screening" id="screening"></div> -->
        <div class="screening">
          <br />
          <span style="color: white; padding-left: 10px">项目简介:
            {{ this.jianjie }}
          </span>
        </div>

        <div class="controls">
          <div style="width:96%;margin: 0 auto;color: #fff;margin-top: 10px; " @click="tiaozhuan5">
            <div>隐患排查台账</div>
            <el-table :data="tableData" height="250" ref="table"
              :header-cell-style="{ background: '#142444', color: '#fff', border: 'none' }"
              style="width: 100%;overflow: hidden;">
              <el-table-column prop="taskname" label="检查类型">
              </el-table-column>
              <el-table-column prop="tasktype" label="隐患级别">
              </el-table-column>
              <el-table-column prop="dutyname" label="检查人">
              </el-table-column>
              <el-table-column prop="audit_type" label="治理状态">
              </el-table-column>
              <el-table-column prop="reformname" label="整改人">
              </el-table-column>
              <el-table-column prop="refortime" width="110px" label="检查时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <table border="1" class="tab" v-show="false">
          <tr>
            <td>危险度（D）分值</td>
            <td>颜色</td>
            <td>风险等级</td>
          </tr>
          <tr>
            <td>320</td>
            <td class="red"></td>
            <td>重大</td>
          </tr>
          <tr>
            <td>160~320</td>
            <td class="orange"></td>
            <td>较大</td>
          </tr>
          <tr>
            <td>70~160</td>
            <td class="yellow"></td>
            <td>一般</td>
          </tr>
          <tr>
            <td>70</td>
            <td class="blue"></td>
            <td>低风险</td>
          </tr>
        </table>
        <div class="assessment" id="assessment"></div>
        <!-- <div class="assessment" id="assessment" style="    width: 1000px;height:1000px;"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getReportInfo } from "../request/report";
import * as echarts from "echarts";
import {
  MAPS,
  train,
  trains,
  getRiskNum,
  fourS,
  zongji,
  yh_paicha,
  t_zhang
} from "../request/visualization";
import { number } from 'echarts';
import {
  personnelInformation
} from "../request/risk";
export default {
  name: "visualization",
  data() {
    return {
      timer: null,
      data_list: [],
      activeNames: '',
      tableData: [],//隐患排查台账
      dialogVisible: false,
      mapdata: [
        ["长垣县", 41, "Engineer", 1, "2011-02-12"],
        ["辉县市", 20, "Teacher", 2, "2011-03-01"],
        ["卫辉市", 52, "Musician", 3, "2011-02-14"],
        ["新乡县", 37, "Teacher", 4, "2011-02-18"],
        ["获嘉县", 25, "Engineer", 5, "2011-04-02"],
        ["红旗区", 19, "Teacher", 6, "2011-01-16"],
        ["原阳县", 71, "Engineer", 7, "2011-03-19"],
        ["卫滨区", 36, "Musician", 8, "2011-02-24"],
        ["牧野区", 67, "Engineer", 9, "2011-03-12"],
        ["延津县", 67, "Teache", 10, "2011-03-11"],
      ],
      // 新乡市各县坐标
      dataArr: [
        {
          name: "辉县市",
          cp: [113.8067, 35.46307],
        },
        {
          name: "卫辉市",
          cp: [114.06454, 35.39843],
        },
        {
          name: "获嘉县",
          cp: [113.66159, 35.26521],
        },
        {
          name: "新乡县",
          cp: [113.80511, 35.19075],
        },
        {
          name: "凤泉区",
          cp: [113.91507, 35.38399],
        },
        {
          name: "牧野区",
          cp: [113.9086, 35.3149],
        },
        {
          name: "卫滨区",
          cp: [113.8917, 35.2589],
        },
        {
          name: "红旗区",
          cp: [113.87523, 35.30367],
        },
        {
          name: "延津县",
          cp: [114.20266, 35.14327],
        },
        {
          name: "原阳县",
          cp: [113.93994, 35.06565],
        },
        {
          name: "封丘县",
          cp: [113.812, 35.468],
        },
        {
          name: "长垣县",
          cp: [114.66882, 35.20046],
        },
      ],
      map: {},
      riskData: [], //风险饼图数据
      riskNum: "", //风险总数
      reportData: [], //隐患饼图数据
      reportNum: "", //隐患总数
      jianjie: "", //项目简介
      fengxainshu: [], // 各项风险书
      fengxianzhi: [], // 各项风险书
      name: [], // 名字
      image: "", //四色图
      yh_zong: 0, //隐患总计
      yiban_yihuan: 0, //一般隐患
      zhongda_yinhuan: 0, //重大隐患
      yibiao: 0, //仪表盘
      dai_zg: 0, //待整改
      dai_ys: 0, //待验收
      yi_wc: 0, //已完成
      pc_zongji: 0, //隐患排查总计
      msgs: {},
    };
  },
  directives: {
    drag: {
      inserted: function (el) {
        // el.drag();
        //获取元素
        // var dv = document.getElementById("dv");
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        //鼠标按下事件
        let els = el;
        els.onmousedown = function (e) {
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          isDown = true;
          //设置样式
          el.style.cursor = "move";
          //鼠标移动
          window.onmousemove = function (e) {
            if (isDown == false) {
              return;
            }
            //获取x和y
            let nx = e.clientX;
            let ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            let nl = nx - (x - l);
            let nt = ny - (y - t);
            console.log(nl, nt);
            if (nl > 1300) {
              nl = 1300;
            }
            if (nl < 560) {
              nl = 560;
            }
            if (nt < 70) {
              nt = 70;
            }
            if (nt > 900) {
              nt = 900;
            }

            el.style.left = nl + "px";
            el.style.top = nt + "px";
          };
        };

        //鼠标抬起事件
        el.onmouseup = function (e) {
          //开关关闭
          isDown = false;
          el.style.cursor = "default";
        };
      },
    },
  },
  created() {
  },
  mounted() {

    setTimeout(() => {
      this.msgs = {
        unique_identification: localStorage.getItem("uid"),
      }
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          this.msgs.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          this.msgs.firm_id = localStorage.getItem('project')
        }
      }
    }, 200)
    setTimeout(() => {
      this.personnelInformation()
    }, 400)
  },
  methods: {
    //通过项目id获取人员信息
    async personnelInformation() {

      const res = await personnelInformation(this.msgs)
      console.log(res, '通过项目id获取人员信息');
      if (res.data.data.length > 0) {
        this.data_list = res.data.data
        this.activeNames = res.data.data[0].id
      }
      this.getMAP()
      this.yinong();
      this.taizhang()
      this.paicha();
      //   风险
      this.getRiskInfo();
      // 隐患
      this.getReportInfo();
      this.getfourP();
      // 体系评估排名
      this.$nextTick(() => {
        this.initassessment();
      });
      // 风险分级管控概况
      this.$nextTick(() => {
        this.initcontrols();
      });
      //  隐患排查概况
      this.$nextTick(() => {
        this.initscreening();
      });
      //  初始化地图
      this.initmap();
      //  数量统计
      this.$nextTick(() => {
        this.initbar();
      });
      // 获取项目培训完成度和公司培训完成度
      this.getInittraining();
    },

    handleClick(tab, event) {
      this.getMAP()
      this.yinong();
      this.taizhang()
      this.paicha();
      //   风险
      this.getRiskInfo();
      // 隐患
      this.getReportInfo();
      this.getfourP();
      // 体系评估排名
      this.$nextTick(() => {
        this.initassessment();
      });
      // 风险分级管控概况
      this.$nextTick(() => {
        this.initcontrols();
      });
      //  隐患排查概况
      this.$nextTick(() => {
        this.initscreening();
      });
      //  初始化地图
      this.initmap();
      //  数量统计
      this.$nextTick(() => {
        this.initbar();
      });
      // 获取项目培训完成度和公司培训完成度
      this.getInittraining();
    },
    tiaozhuan1() {
      this.$router.push("/RecordsFebruary")
    },
    tiaozhuan2() {
      this.$router.push("/RecordsFebruary")
    },
    tiaozhuan3() {
      this.$router.push("/RecordsFebruary")
    },
    tiaozhuan4() {
      this.$router.push("/Hidden")
    },
    tiaozhuan5() {
      this.$router.push("/HiddenGovernance")
    },
    gundong() {
      // 清除之前的计时器
      if (this.timer) {
        clearInterval(this.timer);
      }
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.table
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      this.timer = setInterval(() => {
        // 元素自增距离顶部1像素
        divData.scrollTop += 1
        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
          // 重置table距离顶部距离
          divData.scrollTop = 0
        }
      }, 100)
    },
    async taizhang() {
      let msg = {
        page: 1,
        size: 9999,
        project_id: this.activeNames
      }
      let res = await t_zhang(msg)
      console.log(res, "------------------");
      this.tableData = res.data.data
      this.gundong()
    },
    zhuzhunagtu(data1, data2) {
      var chartDom = document.getElementById('zhutu');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: 'category',
          data: data1,
          axisLabel: {
            interval: 0,
            rotate: 20,
            color: "#fff"
          },
        },
        yAxis: {
          type: 'value',
          name: '七日排查次数',
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
          },

          axisLabel: {


            color: "#fff"
          },
        },
        series: [
          {
            data: data2,
            type: 'bar'
          }
        ]
      };

      option && myChart.setOption(option);
    },
    chuxian(num) {
      yinhuan_cxl;
      var chartDom = document.getElementById("yinhuan_cxl");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            axisLabel: {
              show: false, // 不显示坐标轴上的文字
            },

            axisTick: {
              show: false, // 不显示坐标轴刻度线
            },
            axisLine: {
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#5470C6"]],
              },
            },

            splitLine: {
              show: false, // 不显示网格线
            },

            detail: {
              formatter: "{value}",
              // show: true,             // 是否显示详情,默认 true。
              offsetCenter: [0, "60%"], // 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 auto。
              fontSize: 18, // 文字的字体大小,默认 15。
              // formatter: "{value}%",  // 格式化函数或者字符串
            },
            data: [
              {
                value: num,
                name: "隐患出现率",
                tooltip: {
                  color: "#fff",
                },
              },
            ],
            title: {
              offsetCenter: [0, "110%"], //设置完成率位置
              color: "#fff",
              fontSize: 14,
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    fenbu(xj, jjb, zj) {
      var chartDom = document.getElementById("yuan");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          top: "5%",
          left: "center",
        },

        //鼠标移入显示的文字
        tooltip: {
          trigger: "item",
          formatter: "{b}<br/> {c}    ",
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["60%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: xj, name: "日常班中巡检" },
              { value: jjb, name: "日常班中交接班" },
              { value: zj, name: "项目周检" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    async paicha() {
      let msg = {
        pro_id: this.activeNames,
      };
      let res = await yh_paicha(msg);
      console.log(res.data, "================");
      let a = res.data.data;
      this.pc_zongji = a.data_work_jjb + a.data_work_xj + a.data_work_zj;
      this.fenbu(
        res.data.data.data_work_xj,
        res.data.data.data_work_jjb,
        res.data.data.data_work_zj
      );
      this.chuxian(res.data.num);
      let data1 = []
      let data2 = []
      for (const key in res.data.time_list) {
        data1.unshift(key)
        data2.push(res.data.time_list[key])
      }

      this.zhuzhunagtu(data1, data2)
    },
    // 隐患治理完成率可视化
    zhili() {
      var chartDom = document.getElementById("yh_zhili");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            axisLabel: {
              show: false, // 不显示坐标轴上的文字
            },

            axisTick: {
              show: false, // 不显示坐标轴刻度线
            },
            axisLine: {
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#5470C6"]],
              },
            },

            splitLine: {
              show: false, // 不显示网格线
            },

            detail: {
              formatter: "{value}",
              // show: true,             // 是否显示详情,默认 true。
              offsetCenter: [0, "60%"], // 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 auto。
              fontSize: 18, // 文字的字体大小,默认 15。
              // formatter: "{value}%",  // 格式化函数或者字符串
            },
            data: [
              {
                value: this.yibiao,
                name: "隐患治理完成率",
                tooltip: {
                  color: "#fff",
                },
              },
            ],
            title: {
              offsetCenter: [0, "110%"], //设置完成率位置
              color: "#fff",
              fontSize: 14,
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 隐患总计
    async yinong() {
      let msg = {
        pro_id: this.activeNames,
      };
      let res = await zongji(msg);
      console.log(res.data.data, "总计+++++++++++++");
      this.yh_zong = res.data.data.yiban + res.data.data.zhongda;
      this.yiban_yihuan = res.data.data.yiban;
      this.zhongda_yinhuan = res.data.data.zhongda;
      this.yibiao = Number(res.data.data.wanchenglv).toFixed(2);

      this.dai_zg = res.data.data.daizhenggai;
      this.dai_ys = res.data.data.daiyanshou;
      this.yi_wc = res.data.data.yiwancheng;
      this.zhili();
    },
    //跳转画布页面
    tz() {
      this.$router.push("/canvas");
    },
    async upimg() {
      console.log("点击图片唤醒弹出层");
    },
    // 获取培训概况数据
    async getInittraining() {
      let msg = {
        project_id: this.activeNames

      };
      const res = await train(msg);

      //  console.log(res);
      let msg1 = {
        company_id: localStorage.getItem("companys"),
      };
      const res1 = await trains(msg1);
      console.log(res1);
      // 培训概况
      this.$nextTick(() => {
        // this.inittraining(
        //   res.data.msg,
        //   res.data.msg1 - res.data.msg,
        //   res1.data.msg,
        //   res1.data.msg1 - res1.data.msg
        // );
      });
    },
    // 获取风险概况数据
    async getMAP() {
      let msg = {
        corporation: this.activeNames,
      };
      const res = await MAPS(msg);
      console.log(res);
      this.fengxainshu = res.data.datas;
      this.fengxianzhi = res.data.values;
      this.name = res.data.name;

      this.$nextTick(() => {
        this.initassessment();
      });
      for (var i = 0; i < this.mapdata.length; i++) {
        this.mapdata[i][3] = res.data[i];
      }
      this.$nextTick(() => {
        this.initbar();
      });
    },
    // 四色图
    async getfourP() {
      let msg = {
        pro_id: this.activeNames,
      };
      const { data: res } = await fourS(msg);
      this.image = res.img;
    },
    // 获取风险概况数据
    async getRiskInfo() {
      let msg = {
        name: "risk",
        id: this.activeNames,
      };
      const { data: res } = await getRiskNum(msg);
      console.log(res.data);
      this.jianjie = res.data;
      this.riskNum = res.all_num;
      this.riskData.push(res.low, res.ordinary, res.larger, res.severity);
      //  console.log(this.riskData);
      // this.$nextTick(()=>{
      //   this.initRisk();
      // })
      this.initRisk();
    },
    // 获取隐患数据
    async getReportInfo() {
      let msg = {
        name: "hidd",
        id: this.activeNames,
      };
      const { data: res } = await getRiskNum(msg);
      console.log(res);
      this.reportNum = res.add_num;
      this.reportData.push(res.ordinary, res.severity);
      this.initReport();
    },
    // getCity() {
    //   this.$axios.get(
    //     "api.map.baidu.com/api_region_search/v1/?keyword=新乡sub_admin=2&ak=O9CGqgSNqDRQK5oFoG4UgBonBH87wKxu"
    //   ).then((res)=>{
    //     console.log(res);
    //   })
    // },
    // 初始化地图
    initmap() {
      this.map = new BMap.Map("map");
      var point = new BMap.Point(113.74833, 35.570927);
      // var point = new BMap.Point(113.883991,35.302616);
      this.map.disableDoubleClickZoom();
      //  this.map.enableScrollWheelZoom(false); //开启鼠标滚轮缩放
      this.map.centerAndZoom(point, 10); //去除路网
      this.map.setMapStyle({
        styleJson: [
          {
            style: "midnight",
            featureType: "road",
            elementType: "all",
            stylers: {
              //"color": "#ffffff",
              visibility: "off",
            },
          },
        ],
      });

      this.map.clearOverlays();
      const bdary = new BMap.Boundary();
      bdary.get("辉县市", (rs) => {
        const count = rs.boundaries.length;
        if (count === 0) {
          return;
        }
        const EN_JW = "180, 90;";
        const NW_JW = "-180,  90;";
        const WS_JW = "-180, -90;";
        const SE_JW = "180, -90;";
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(
          rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          {
            strokeColor: "none",
            fillColor: "#142444",
            fillOpacity: 1,
            strokeOpacity: 1,
          }
        );
        this.map.addOverlay(ply1);
        // 绘制‘贵州省’整体的外轮廓
        for (let i = 0; i < count; i++) {
          const ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 0.5,
            strokeColor: "transparent",
            fillColor: "transparent",
          });
          this.map.addOverlay(ply);
        }
        // this.dataArr.forEach((element) => {
        //   var bdary = new BMap.Boundary();
        //   bdary.get(element["name"], (rs) => {
        //     var count = rs.boundaries.length;
        //     for (let i = 0; i < count; i++) {
        //       var ply = new BMap.Polygon(rs.boundaries[i], {
        //         strokeWeight: 0.5,
        //         strokeColor: "#ccc",
        //         fillOpacity: 0.6,
        //         fillColor: "#4c7cfc",
        //       });
        //       this.map.addOverlay(ply);
        //     }
        //     // this.citySetLabel(
        //     //   new BMap.Point(element["cp"][0], element["cp"][1]),
        //     //   element["name"]
        //     // );
        //   });
        // });
      });
    },
    // citySetLabel(cityCenter, cityName) {
    //   var label = new BMap.Label(cityName, {
    //     offset: new BMap.Size(-20, -10),
    //     position: cityCenter,
    //   });
    //   label.setStyle({
    //     border: "none",
    //     background: "transparent",
    //     "font-size": "0.25rem",
    //     color: "#ccc",
    //   });
    //   this.map.addOverlay(label);
    // },
    // 工地风险隐患数量统计
    initbar() {
      let myEcharts = this.$echarts.init(document.getElementById("bar"));
      let option = {
        title: {
          text: "各区域工地风险数据汇总",
          top: "30",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        dataset: [
          {
            dimensions: ["name", "age", "profession", "score", "date"],
            source: this.mapdata,
          },
          {
            transform: {
              type: "sort",
              config: { dimension: "score", order: "desc" },
            },
          },
        ],
        xAxis: {
          type: "category",
          axisLabel: { interval: 0, rotate: 30 },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        series: {
          type: "bar",
          encode: { x: "name", y: "score" },
          datasetIndex: 1,
        },
      };
      myEcharts.setOption(option);
    },
    //   风险概况
    initRisk() {
      let myEcharts = this.$echarts.init(document.getElementById("risk"));
      let option = {
        // title: {
        //   text: "风险概况",
        //   left: "left",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "10%",
          orient: "vertical",
          left: "left",
          data: ["低风险", "一般风险", "较大风险", "重大风险"],
          itemGap: 20, // 设置间距
          textStyle: {
            color: "#fff",
          },

          formatter: (name) => {
            var index = 0;
            var clientlabels = ["低风险", "一般风险", "较大风险", "重大风险"];
            var clientcounts = this.riskData;
            // var clientcounts = [10,53,86,48];
            clientlabels.forEach(function (value, i) {
              if (value == name) {
                index = i;
              }
            });
            return name + "  " + clientcounts[index];
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            // label: {
            //   show: false,
            //   position: "center",
            // },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.riskData[0], name: "低风险" },
              { value: this.riskData[1], name: "一般风险" },
              { value: this.riskData[2], name: "较大风险" },
              { value: this.riskData[3], name: "重大风险" },
            ],
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 隐患概况
    initReport() {
      let myEcharts = this.$echarts.init(document.getElementById("report"));
      let option = {
        // title: {
        //   text: "隐患概况",
        //   left: "left",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "10%",
          orient: "vertical",
          left: "left",
          data: ["一般隐患", "重大隐患"],
          itemGap: 20, // 设置间距
          y: "50%",
          textStyle: {
            color: "#fff",
          },
          formatter: (name) => {
            var index = 0;
            var clientlabels = ["一般隐患", "重大隐患"];
            var clientcounts = this.reportData;
            clientlabels.forEach(function (value, i) {
              if (value == name) {
                index = i;
              }
            });
            return name + "  " + clientcounts[index];
          },
        },
        series: [
          {
            type: "pie",
            radius: ["70%"],
            avoidLabelOverlap: false,
            // label: {
            //   show: false,
            //   position: "center",
            // },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "20",
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.reportData[0], name: "一般隐患" },

              { value: this.reportData[1], name: "重大隐患" },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#fcac04", "#d40c0c"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myEcharts.setOption(option);
    },
    //   跳转
    toHome() {
      this.$router.push("/Upload");
    },
    //   培训概况
    inittraining(msg1, msg2, msg3, msg4) {
      let myEcharts = this.$echarts.init(document.getElementById("training"));
      var data1 = [
        {
          name: "完成",
          value: msg1,
        },
        {
          name: "未完成",
          value: msg2,
        },
      ];
      var data2 = [
        {
          name: "完成",
          value: msg3,
        },
        {
          name: "未完成",
          value: msg4,
        },
      ];
      let option = {
        title: [
          {
            text: "培训概况",
            left: "left",
            textStyle: {
              color: "#fff",
            },
          },
          {
            subtext: "企业完成占比",
            left: "14%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "项目完成占比",
            left: "68%",
            top: "75%",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            data: data2,
            label: {
              show: false,
              //   position: "outer",
              //   alignTo: "none",
              //   bleedMargin: 5,
            },
            left: 0,
            right: "66.6667%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["110%", "50%"],
            data: data1,
            label: {
              show: false,
              //   position: "outer",
              //   alignTo: "labelLine",
              //   bleedMargin: 5,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 体系评估排名
    initassessment() {
      let myEcharts = this.$echarts.init(document.getElementById("assessment"));

      let option = {
        title: {
          text: "作业安全风险比较图",

          textStyle: {
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["风险数", "风险值"],
          color: ["red", "red"],
          textStyle: {
            color: "white",
          },
        },
        color: ["#44CED5", "white"],
        calculable: true,
        xAxis: [
          {
            type: "category",
            textStyle: {
              fontSize: 1,
            },
            data: this.name,

            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: -55, //-30度角倾斜显示
              color: "#fff",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "风险数",
            type: "bar",
            data: this.fengxainshu,

            barWidth: 13,
          },
          {
            name: "风险值",
            type: "bar",
            data: this.fengxianzhi,
            //设置柱子的宽度
            barWidth: 13,
            //配置样式
            itemStyle: {
              //通常情况下：
              normal: {
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                color: function (params) {
                  // console.log(params.data);
                  var data = parseInt(params.data);
                  var colorList = ["blue", "Yellow", "orange", "red"];
                  if (70 > data) {
                    console.log("1");
                    return colorList[0];
                  } else if (160 > data && data >= 70) {
                    // console.log("2");
                    return "Yellow";
                  } else if (320 > data && data >= 160) {
                    // console.log("3");
                    return colorList[2];
                  } else if (data >= 320) {
                    // console.log("4");
                    // return colorList[3];
                    return "red";
                  }
                },
              },
              //鼠标悬停时：
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myEcharts.setOption(option);
    },
    //   风险分级管控概况
    initcontrols() {
      let myEcharts = this.$echarts.init(document.getElementById("controls"));
      var data = [
        {
          name: "完成",
          value: 70,
        },
        {
          name: "应完成",
          value: 68,
        },
      ];
      let option = {
        title: [
          {
            text: "培训概况",
            left: "left",
            textStyle: {
              color: "#fff",
            },
          },
          {
            subtext: "分析点确认",
            left: "12%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险评价",
            left: "35%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险控制措施",
            left: "62%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险分级管控",
            left: "87%",
            top: "75%",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["40%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: 0,
            right: "66.6667%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["10%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["90%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["160%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    //   隐患排查概况
    initscreening() {
      let myEcharts = this.$echarts.init(document.getElementById("screening"));
      var data = [
        {
          name: "完成",
          value: 70,
        },
        {
          name: "应完成",
          value: 68,
        },
      ];
      let option = {
        title: [
          {
            text: "培训概况",
            left: "left",
            textStyle: {
              color: "#fff",
            },
          },
          {
            subtext: "分析点确认",
            left: "14%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险评价",
            left: "35%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险控制措施",
            left: "62%",
            top: "75%",
            textAlign: "center",
          },
          {
            subtext: "风险分级管控",
            left: "87%",
            top: "75%",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["40%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: 0,
            right: "66.6667%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["10%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["90%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
          {
            type: "pie",
            radius: ["30%", "50%"],
            center: ["160%", "50%"],
            data: data,
            label: {
              show: false,
            },
            left: "33.3333%",
            right: "33.3333%",
            top: 0,
            bottom: 0,
          },
        ],
      };
      myEcharts.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0c1327;

  .top {
    flex: 1;
    display: flex;
    // background-color: snow;
    // padding: 0;
    // margin: 0 20px;
    background-color: #142444;

    .goBack {
      cursor: pointer;
      padding: 20px;
      padding-bottom: 0;
      color: rgb(77, 124, 254);
    }
  }

  .home {
    flex: 15;
    width: 100%;
    // height: 100%;
    display: flex;
    padding: 10px 20px;
    overflow-x: hidden;

    .left {
      flex: 2;
      display: flex;
      flex-direction: column;

      //   background-color: pink;
      .risk {
        flex: 1;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #142444;
        display: flex;
        flex-direction: column;
      }

      .report {
        flex: 1;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #142444;
        display: flex;
        flex-direction: column;
      }

      .training {
        flex: 1;
        padding: 10px;
        background-color: #142444;
      }

      // 公共样式
      .risk_top {
        flex: 1;

        // background-color: pink;
        .risk_title {
          color: #fff;
          font-weight: 700;
          font-style: 20px;
        }

        .risk_count {
          text-align: center;
          height: 26px;
          line-height: 26px;
          color: #fff;
        }
      }

      .risk_body {
        flex: 2;
      }
    }

    .center {
      flex: 3;
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      background-color: #142444;

      .map {
        flex: 3;
        // background-color: brown;
      }

      .bar {
        flex: 2;
        // background-color: burlywood;
      }
    }

    .right {
      flex: 2;
      display: flex;
      flex-direction: column;

      //   background-color: skyblue;
      .assessment {
        flex: 1;
        margin-bottom: 10px;
        background-color: #142444;
        //background-color: #ffffff;
      }

      .controls {
        flex: 1;
        margin-bottom: 10px;
        background-color: #142444;
      }

      .screening {
        flex: 1;
        background-color: #142444;
        margin-bottom: 10px;
      }
    }

    .img {
      height: 100%;
      width: 100%;
    }

    .imgs {
      height: 80%;
      left: 30%;
      top: 10%;
      position: absolute;
    }

    .tab {
      width: 190px;
      color: white;
      font-size: 1px;
      position: fixed;
      bottom: 145px;
      right: 25px;
      z-index: 999;
      background-color: #142444;
    }

    .tabs {
      width: 90px;
      color: white;
      font-size: 1px;
      position: fixed;
      bottom: 145px;
      right: 600px;
      top: 80px;
      z-index: 999;
      background-color: #142444;
    }

    .red {
      background-color: red;
    }

    .orange {
      background-color: orange;
    }

    .yellow {
      background-color: yellow;
    }

    .blue {
      background-color: blue;
    }

    .qx {
      top: 90%;
      left: 95%;
      position: absolute;
    }

    .qd {
      top: 90%;
      left: 90%;
      position: absolute;
    }

    #box {
      width: 60px;
      height: 60px;
      top: 100px;
      left: 600px;
      background-color: rgba(0, 17, 255, 0.61);
      border-radius: 50%;
      position: absolute;
      z-index: 9999;
      color: rgb(255, 255, 255);
      //脱离文档流
    }

    #box1 {
      width: 60px;
      height: 60px;
      top: 100px;
      left: 700px;
      background-color: rgba(229, 255, 0, 0.596);
      border-radius: 50%;
      position: absolute;
      z-index: 9999;
      color: rgb(255, 255, 255);
      //脱离文档流
    }

    #box2 {
      width: 60px;
      height: 60px;
      top: 100px;
      left: 800px;
      background-color: hsla(34, 98%, 51%, 0.521);
      border-radius: 50%;
      position: absolute;
      z-index: 9999;
      color: rgb(255, 255, 255);

      //脱离文档流
    }

    #box3 {
      width: 60px;
      height: 60px;
      top: 100px;
      left: 900px;
      background-color: rgba(255, 0, 0, 0.575);
      border-radius: 50%;
      position: absolute;
      z-index: 9999;
      color: rgb(255, 255, 255);
      //脱离文档流
    }
  }
}
</style>
<style >
.controls .el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: rgba(255, 255, 255, );
  background: #142444;
  border: none;
  color: #fff;
}

.controls .el-table--border {
  /* border-right: none; */
  /* border-bottom: none; */
  border: none;
}

.controls .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #142444 !important;
}

.controls .el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}

.el-tabs__item {
  color: #4d6391;
}
</style>