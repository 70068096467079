//数据首页请求
// 提=获取项目培训完成度
import { request } from "./request"


// 获取风险数据
export function getRiskNum(msg) {
    return request({
        url: "api/a_s/",
        method: "post",
        data: msg
    })

}

// 项目培训完成度
export function train(msg) {
    return request({
        url: "v1/train/",
        method: "get",
        params: msg
    })

}
// 公司培训完成度
export function trains(msg) {
    return request({
        url: "v1/train/",
        method: "put",
        params: msg
    })

}


// 地图统计信息
export function MAPS(msg) {
    return request({
        url: "api/Map/",
        method: "post",
        params: msg
    })

}


// 获取四色图从
export function fourS(msg) {
    return request({
        url: "api/Pro_item/",
        method: "post",
        data: msg
    })

}
export function zongji(msg) {
    return request({
        url: "api/Count_yinhuan/",
        method: "post",
        data: msg
    })

}
export function yh_paicha(msg) {
    return request({
        url: "api/Count_yinhuan/",
        method: "get",
        params: msg
    })

}
export function t_zhang(msg) {
    return request({
        url: "api/hdd_a/",
        method: "post",
        data: msg
    })

}
 